<template>
    <section class="about_section layout_padding">
      <div class="container">
        <div class="heading_container heading_center">
          <h2>{{ aboutData.title.split(' ')[0] }} <span>{{ aboutData.title.split(' ')[1] }}</span></h2>
          <p>{{ aboutData.headerText }}</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="img-box">
              <img :src="aboutData.image" alt="">
            </div>
          </div>
          <div class="col-md-6">
            <div class="detail-box">
              <h3>{{ aboutData.subtitle }}</h3>
              <p v-html="aboutData.description"></p>
              <!-- <a :href="aboutData.buttonLink">{{ aboutData.buttonText }}</a> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'AboutSection',
    props: {
      aboutData: {
        type: Object,
        required: true
      }
    }
  }
  </script>