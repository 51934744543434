<template>
  <div class="hero_bg_box">
    <div class="bg_img_box">
      <img src="images/hero-bg.png" alt="">
    </div>
  </div>
  <!-- header section strats -->
  <header class="header_section">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg custom_nav-container">
        <a class="navbar-brand" :href="logo.link">
          <span>{{ logo.text }}</span>
        </a>
        <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
          <span></span>
        </button> -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li v-for="item in navItems" :key="item.text" 
            :class="['nav-item', { active: item.active }]">
            <a class="nav-link" :href="item.link">{{ item.text }}</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">
              <i class="fa fa-user" aria-hidden="true"></i> Login
            </a>
          </li> -->
        </ul>
      </div>
    </nav>
  </div>
  </header>
</template>
  
  <script>
  export default {
    name: 'NavBar',
    props: {
      logo: {
        type: Object,
        required: true
      },
      navItems: {
        type: Array,
        required: true
      }
    }
  }
  </script>