<template>
      <div class="hero_area">
        <NavBar :navItems="navData" :logo="logoData" />
        <HeroSection :heroData="heroContent" />
      </div>
      <ServiceSection :services="servicesData" />
      <AboutSection :aboutData="aboutContent" />
      <WhyUsSection :reasons="whyUsData" />
      <!-- <TeamSection :teamMembers="teamData" /> -->
      <FooterSection 
      :contactInfo="footerContactInfo"
      :socialLinks="socialLinks"
      :links="links"
      :infoText="infoText"
      />
</template>
<style>
  /* @import url("/assets/css/bootstrap.css"); */
  /* @import url("/assets/css/font-awesome.min.css"); */
  /* @import url("/assets/css/responsive.css"); */
  /* @import url("/assets/css/style.css"); */
</style>
<script>
import NavBar from './components/NavBar.vue';
import HeroSection from './components/HeroSection.vue';
import ServiceSection from './components/ServiceSection.vue';
import AboutSection from './components/AboutSection.vue';
import WhyUsSection from './components/WhyUsSection.vue';
// import TeamSection from './components/TeamSection.vue';
import FooterSection from './components/FooterSection.vue';

// Import the JSON file
import jsonData from './assets/data.json';

export default {
  name: 'App',
  components: {
    NavBar,
    HeroSection,
    ServiceSection,
    AboutSection,
    WhyUsSection,
    // TeamSection,
    FooterSection,
  },
  data() {
    return {
      logoData: jsonData.logoData,
      navData: jsonData.navData,
      heroContent: jsonData.heroContent,
      servicesData: jsonData.servicesData,
      aboutContent: jsonData.aboutContent,
      whyUsData: jsonData.whyUsData,
      teamData: jsonData.teamData,
      footerContactInfo: jsonData.footerContactInfo,
      socialLinks: jsonData.socialLinks,
      links: jsonData.links,
      infoText: jsonData.infoText
    };
  },
};
</script>

