<template>
    <section class="slider_section">
      <div id="customCarousel1" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div v-for="(slide, index) in heroData.slides" 
               :key="index"
               :class="['carousel-item', { active: index === 0 }]">
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <div class="detail-box">
                    <h1>{{ slide.title }}</h1>
                    <p>{{ slide.description }}</p>
                    <div class="btn-box">
                      <!-- <a :href="slide.buttonLink" class="btn1">
                        {{ slide.buttonText }}
                      </a> -->
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="img-box">
                    <img :src="slide.image" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ol class="carousel-indicators">
          <li v-for="(slide, index) in heroData.slides"
              :key="'indicator-'+index"
              data-target="#customCarousel1"
              :data-slide-to="index"
              :class="{ active: index === 0 }">
          </li>
        </ol>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'HeroSection',
    props: {
      heroData: {
        type: Object,
        required: true
      }
    }
  }
  </script>