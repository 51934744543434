<template>
  <div>
    <section class="info_section layout_padding2">
      <div class="container">
        <div class="row">
          <!-- Contact Info Column -->
          <div class="col-md-6 col-lg-3 info_col">
            <div class="info_contact">
              <h4>Contact Info</h4>
              <div class="contact_link_box">
                <div v-for="(value, key) in contactInfo" :key="key">
                  <a :href="value.link ? value.link : ''">
                    <i :class="`fa fa-${key}`" aria-hidden="true"></i>
                    <span>{{ value.text }}</span>
                  </a>
                  <hr>
                </div>
              </div>
            </div>
            <div class="info_social">
              <a v-for="(link, key) in socialLinks" :href="link" :key="key">
                <i :class="`fa fa-${key}`" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          
          <!-- Info Column -->
          <div class="col-md-6 col-lg-3 info_col">
            <div class="info_detail">
              <h4>Info</h4>
              <p>{{ infoText }}</p>
            </div>
          </div>
          
          <!-- Links Column -->
          <div class="col-md-6 col-lg-2 mx-auto info_col">
            <div class="info_link_box">
              <h4>Links</h4>
              <div class="info_links">
                <a v-for="link in links" 
                   :key="link.text"
                   :class="{ active: link.active }"
                   :href="link.link"
                   style="margin-bottom: 10px;">
                  {{ link.text }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Copyright Footer -->
    <section class="footer_section">
      <div class="container">
        <p>
          &copy; {{ currentYear }} All Rights Reserved By
          <a href="#">Pyraguard</a>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'FooterSection',
  props: {
    contactInfo: {
      type: Object,
      required: true
    },
    socialLinks: {
      type: Object,
      required: true
    },
    links: {
      type: Array,
      required: true
    },
    infoText: {
      type: String,
      default: 'necessary, making this the first true generator on the Internet.'
    }
  },
  data() {
    return {
      email: '',
      currentYear: new Date().getFullYear()
    }
  },
  methods: {
    handleSubscribe() {
      // Handle newsletter subscription
      console.log('Subscribe email:', this.email);
      // Add your subscription logic here
      this.email = ''; // Reset form
    }
  }
}
</script>