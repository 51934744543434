<template>
    <section class="why_section layout_padding">
      <div class="container">
        <div class="heading_container heading_center">
          <h2>Why Choose <span>Us</span></h2>
        </div>
        <div class="why_container">
          <div v-for="reason in reasons" :key="reason.title" class="box">
            <div class="img-box">
              <img class="background-gif" :src="reason.image" :alt="reason.title">
            </div>
            <div class="detail-box">
              <h5>{{ reason.title }}</h5>
              <p>{{ reason.description }}</p>
            </div>
          </div>
        </div>
        <!-- <div class="btn-box">
          <a href="">Read More</a>
        </div> -->
      </div>
    </section>
  </template>
  <style scoped>
  img .background-gif {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    animation: smoothLoop 2s infinite;
  }
    /* Animation to ensure smooth transitions */
  @keyframes smoothLoop {
    0% {
      opacity: 0; /* Optionally fade in the start of the GIF */
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0; /* Optionally fade out at the end */
    }
  }
  </style>
  <script>
  export default {
    name: 'WhyUsSection',
    props: {
      reasons: {
        type: Array,
        required: true
      }
    }
  }
  </script>