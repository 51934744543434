<template>
    <section class="service_section layout_padding">
      <div class="service_container">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Our <span>Services</span></h2>
          </div>
          <div class="row">
            <div v-for="service in services" 
                 :key="service.title"
                 class="col-md-4">
              <div class="box">
                <div class="img-box">
                  <img :src="service.image" alt="">
                </div>
                <div class="detail-box">
                  <h5>{{ service.title }}</h5>
                  <p>{{ service.description }}</p>
                  <!-- <a :href="service.link">Read More</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'ServiceSection',
    props: {
      services: {
        type: Array,
        required: true
      }
    }
  }
  </script>